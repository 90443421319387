<template>
  <div id="signin_button"></div>
</template>

<script>
export default {
  name: 'GoogleButton',
  props: {
    type: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'prompt'].includes(value)
      }
    }
  },
  computed: {
    clientId () {
      return process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID
    }
  },
  methods: {
    handleCredentialResponse (response) {
      this.$emit('handleCredential', response.credential)
    }
  },
  mounted () {
    window.google.accounts.id.initialize({
      client_id: this.clientId,
      callback: this.handleCredentialResponse,
      auto_select: false
    })

    if (this.type === 'prompt') {
      window.google.accounts.id.prompt()
    } else {
      window.google.accounts.id.renderButton(
        document.getElementById('signin_button'),
        {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          shape: 'pill',
          text: 'continue_with',
          locale: 'id',
          logo_alignment: 'center',
          width: 304
        }
      )
    }
  }
}
</script>
